import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoGoalDetail'
})
export class EvoGoalDetailPipe implements PipeTransform {

  transform(value: any[], filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p => {
      const userName = p.userName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const saleName = p.scaleName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const goalName = p.scaleName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const scaleIndicator = p.scaleIndicator?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const observationPeriodName = p.observationPeriodName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const goalStatusName = p.goalStatusName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      
      const targeted =String(p.targeted)?.includes(filterText);
      const relaised =String(p.relaised)?.includes(filterText);
      const weight =String(p.weight)?.includes(filterText);
      const point =String(p.point)?.includes(filterText);

      return (userName || saleName || goalName || scaleIndicator || observationPeriodName || goalStatusName || targeted || relaised || weight || point);
    });
  }
}
