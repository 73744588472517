import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Partner } from 'app/evo/models/partner';
import { Gender } from 'app/evo/models/gender';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { InformationService } from 'app/evo/services/information.service';
import { ErrorService } from 'app/evo/services/error.service';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';

@Component({
  selector: 'app-evo-customer-add-form',
  templateUrl: './evo-customer-add-form.component.html',
  styles: [
  ]
})
export class EvoPartnerAddFormComponent implements OnInit {

  constructor(
    private partnerService: EvoPartnerService,
    private transactionService: EvoTransactionService,
    private errorService: ErrorService,
    private router: Router,
    private decodeService: DecodeService,
    private informationService: InformationService,
  ) { }

  isCustomer: boolean = true;
  isSupplier: boolean = false;
  myId: number;
  genders: Gender[] = [
    { id: 1, name: "Erkek" },
    { id: 2, name: "Kadın" }
  ];

  gender: Gender = new Gender();

  partner: Partner = new Partner();

  ngOnInit(): void {
    this.myId = this.decodeService.getUserId();
    let item = this.decodeService.getRole();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 8, 5, this.myId).subscribe((res: any) => {
      });
    }
  }
  changePartnerCustomer() {
    if (this.isCustomer == true) {
      this.isCustomer = false;
    }
    else {
      this.isCustomer = true;
    }
  }
  changePartnerSupplier() {
    if (this.isSupplier == true) {
      this.isSupplier = false;
    }
    else {
      this.isSupplier = true;
    }
  }
  agreementRate: string = "";
  add(name: string, title: string, phoneNumber: string, mailAddress: string, mainAddress: string, invoceAddress: string, taxOffice: string, taxNumber: string, description: string) {
    var responseId = 0;
    this.partner = {
      id: 0,
      name: name,
      title: title,
      mailAddress: mailAddress,
      mainAddress: mainAddress,
      invoceAddress: invoceAddress,
      agreementRate: this.isSupplier == true ? this.agreementRate : "0",
      taxOffice: taxOffice,
      taxNumber: taxNumber,
      phoneNumber: phoneNumber,
      information: "",
      description: description,
      isCustomer: this.isCustomer,
      isSupplier: this.isSupplier,

      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    };
    this.partnerService.add(this.partner).subscribe((item: any) => {
      responseId = item.data;
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/updatepartner/" + responseId]);
      }, 1500);
    })


  }
}
