<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Yetkinliklerin Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">
                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText"
                                placeholder="Yetkinlik Arayın.." [(ngModel)]="filterText"
                                [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                    <div class="filter mt-1" *ngIf="userRole=='Admin'|| userRole=='Consultant'">
                        <div class="col-12">
                            <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                placeholder="Kullanıcı Seçiniz " bindLabel="firstName" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.firstName }}
                                        {{item.lastName}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="filter mt-1">
                        <div class="col-12">
                            <ng-select [multiple]="true" [items]="modules" [(ngModel)]="selectedModules"
                                placeholder="Modül Seçiniz " bindLabel="name" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.name }}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-1 ml-1">
                        <button class="btn btn-primary btn-sm ml-1 mr-1" data-bs-toggle="modal"
                            data-bs-target="#addcompetence" rippleEffect title="Yeni Yetkinlik Ekle">
                            <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                        </button>
                        <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                            <i data-feather="file-text" class=""></i>
                        </button>
                    </div>
                </div>
                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <tr>
                            <td>Modül Adı</td>
                            <td>Açıklama</td>
                            <td>Kullanıcı Adı</td>
                            <td>Seviye (1-10)</td>
                            <td>Bir Önceki Seviye</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of competences">
                            <td>{{item.moduleName}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.userName}}</td>
                            <td>{{item.score}}</td>
                            <td>{{item.lastScore}}</td>
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable [rows]="competences |evoCompetence:filterText" [rowHeight]="58"
                    class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50"
                    [scrollbarH]="true" [limit]="basicSelectedOption">
                    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false">
                        <ng-template ngx-datatable-header-template let-value="value"
                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                    (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Modül Adı" prop="moduleName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.moduleName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Açıklama" prop="description" [width]="500">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.description}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="350">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.userName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Seviye" prop="score" [width]="100">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.score}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <a ngbDropdownItem data-bs-toggle="modal" data-bs-target="#updatecompetence"
                                            (click)="get(row.id)"><i data-feather="edit-2"
                                                class="mr-50"></i><span>Güncelle</span></a>
                                        <button ngbDropdownItem (click)="deletedCompetenceId=row.id"
                                            data-bs-toggle="modal" data-bs-target="#deletecompetence">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </core-card-snippet>

            <div class="modal fade" id="addcompetence" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetkinlik Bilgisi Ekle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <form class="" #AddCompetence="ngForm">
                                <div class="modal-body">
                                    <div class="form-group">

                                        <div class="row mb-2" *ngIf="userRole=='Admin'|| userRole=='Consultant'">
                                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                                            <select name="slcUser" class="form-control form-select" required
                                                #slcUser="ngModel" [(ngModel)]="user.id">
                                                <option *ngFor="let item of users" value="{{item.id}}">
                                                    {{item.firstName}}
                                                </option>
                                            </select>
                                            <small class="text-danger" *ngIf="slcUser.invalid&& slcUser.touched">Lütfen
                                                bir Kullanıcı
                                                seçiniz!</small>
                                        </div>

                                        <div class="row mb-2 ml-0 mr-0">
                                            <div class="col-6">
                                                <label for="name-vertical">Modül Seçiniz</label>
                                                <select name="slcModule" class="form-control form-select" required
                                                    #slcModule="ngModel" [(ngModel)]="module.id">
                                                    <option *ngFor="let item of modules" value="{{item.id}}">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <small class="text-danger"
                                                    *ngIf="slcModule.invalid&& slcModule.touched">Lütfen bir Modül
                                                    seçiniz!</small>
                                            </div>
                                            <div class="col-6">
                                                <label for="name-vertical">Seviye giriniz (1 ve 10 arasında)</label>
                                                <input type="number" class="form-control" name="txtAddScore" required
                                                    #txtAddScore="ngModel" ngModel>
                                                <small class="text-danger"
                                                    *ngIf="txtAddScore.invalid&& txtAddScore.touched">Seviye kısmı
                                                    boş
                                                    bırakılamaz!</small>
                                            </div>

                                        </div>

                                        <div class="row mb-2">
                                            <label for="name-vertical">Açıklama giriniz</label>

                                            <textarea name="txtAddDescription" required class="form-control"
                                                #txtAddDescription="ngModel" ngModel></textarea>
                                            <small class="text-danger"
                                                *ngIf="txtAddDescription.invalid&& txtAddDescription.touched">Açıklama
                                                boş bırakılamaz!</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="modal-footer">
                                        <div *ngIf="AddCompetence.valid; else emptyAddCompetenceButton ">
                                            <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                                (click)="add(txtAddDescription.value,txtAddScore.value)"><i
                                                    data-feather="check" class="mr-50"></i>Kaydet</button>
                                        </div>
                                        <ng-template #emptyAddCompetenceButton>
                                            <button type="submit" rippleEffect class="btn btn-success mr-1"
                                                disabled>Kaydet</button>
                                        </ng-template>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="updatecompetence" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetkinlik Bilgisini Güncelle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <form class="" #UpdateCompetence="ngForm">
                                <div class="modal-body">
                                    <div class="form-group">

                                        <div class="row mb-2" *ngIf="userRole=='Admin'|| userRole=='Consultant'">
                                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                                            <select name="slcUser" class="form-control form-select" required
                                                #slcUser="ngModel" [(ngModel)]="competence.userId">
                                                <option *ngFor="let item of users" value="{{item.id}}">
                                                    {{item.firstName}}
                                                </option>
                                            </select>
                                            <small class="text-danger" *ngIf="slcUser.invalid && slcUser.touched">Lütfen
                                                bir Kullanıcı
                                                seçiniz!</small>
                                        </div>

                                        <div class="row mb-2 ml-0 mr-0">
                                            <div class="col-6">
                                                <label for="name-vertical">Modül Seçiniz</label>
                                                <select name="slcModule" class="form-control form-select" required
                                                    #slcModule="ngModel" [(ngModel)]="competence.moduleId">
                                                    <option *ngFor="let item of modules" value="{{item.id}}">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <small class="text-danger"
                                                    *ngIf="slcModule.invalid&& slcModule.touched">Lütfen bir Modül
                                                    seçiniz!</small>
                                            </div>
                                            <div class="col-6">
                                                <label for="name-vertical">Seviye giriniz (1 ve 10 arasında)</label>
                                                <input type="number" class="form-control" [(ngModel)]="competence.score"
                                                    name="txtAddScore" required #txtAddScore="ngModel">
                                                <small class="text-danger"
                                                    *ngIf="txtAddScore.invalid&& txtAddScore.touched">Seviye kısmı
                                                    boş
                                                    bırakılamaz!</small>
                                            </div>

                                        </div>

                                        <div class="row mb-2">
                                            <label for="name-vertical">Açıklama giriniz</label>

                                            <textarea name="txtAddDescription" required class="form-control"
                                                #txtAddDescription="ngModel"
                                                [(ngModel)]="competence.description"></textarea>
                                            <small class="text-danger"
                                                *ngIf="txtAddDescription.invalid&& txtAddDescription.touched">Açıklama
                                                boş bırakılamaz!</small>
                                        </div>

                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="modal-footer">
                                        <div *ngIf="UpdateCompetence.valid; else emptyUpdateCompetenceButton ">
                                            <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                                (click)="update()"><i data-feather="check"
                                                    class="mr-50"></i>Kaydet</button>
                                        </div>
                                        <ng-template #emptyUpdateCompetenceButton>
                                            <button type="submit" rippleEffect class="btn btn-success mr-1"
                                                disabled>Kaydet</button>
                                        </ng-template>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="deletecompetence" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetkinlik Sil</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Kaydı Silmek İstediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="delete(deletedCompetenceId)"><i data-feather="trash"
                                        class="mr-50"></i>Sil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>