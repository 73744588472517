import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Ticket } from '../models/ticket';
import { TicketUser } from '../models/ticketuser';


@Injectable({
  providedIn: 'root'
})
export class EvoTicketService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Ticket[]>{
    return this.httpClient.get<Ticket[]>(this.baseApiUrl+'GetTicketList');
  }

  getTicketUserList():Observable<TicketUser[]>{
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl+'GetTicketUserList');
  }
  getUrgentTicketList():Observable<Ticket[]>{
    return this.httpClient.get<Ticket[]>(this.baseApiUrl+'GetUrgentTicketList');
  }
  
  getTicketCount():Observable<number>{
    return this.httpClient.get<number>(this.baseApiUrl+'GetTicketCount');
  }
  get(id:number):Observable<Ticket>{
    return this.httpClient.get<Ticket>(this.baseApiUrl+'GetTicket/'+id);
  }

  add(ticket:Ticket):Observable<Ticket>{
    return this.httpClient.post<Ticket>(this.baseApiUrl+'AddTicket/',ticket);
  }

  update(ticket:Ticket):Observable<Ticket>{
    return this.httpClient.post<Ticket>(this.baseApiUrl+"UpdateTicket/",ticket);
  }

  delete(ticket:Ticket):Observable<Ticket>{
    return this.httpClient.post<Ticket>(this.baseApiUrl+"DeleteTicket/",ticket);
  }

}
