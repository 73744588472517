import { Pipe, PipeTransform } from '@angular/core';
import { last } from 'rxjs/operators';

@Pipe({
  name: 'evoUser'
})
export class EvoUserPipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const userName = p.userName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const FirstNLastName =(p.firstName.toLocaleLowerCase()+" "+ p.lastName.toLocaleLowerCase()).includes(filterText.toLowerCase());
      const mailAddress = p.mailAddress.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const title=p.title.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      return (userName+FirstNLastName+mailAddress+title);
    });
  }

}
