import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Observable, Subject, config } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/menu/menu';
import { locale as menuTurkish } from 'app/menu/i18n/tr'
import { colors } from 'app/colors.const';
import { DashboardService } from './services/dashboard.service';
import { DecodeService } from './login/service/decode.service';
import { EvoTicketService } from './services/evo-ticket.service';
import { EvoUserService } from './services/evo-user.service';
import { User } from './models/user';
import { EvoTicketUserService } from './services/evo-ticketuser.service';
import { ErrorService } from './services/error.service';
import { InformationService } from './services/information.service';
import { TicketUser } from './models/ticketuser';
import { EvoPartnerUserService } from './services/evo-customer-user.service';
import { Ticket } from './models/ticket';
import { Priority } from './models/priority';
import { Status } from './models/status';
import { EvoTicketMessageService } from './services/evo-ticketmessage.service';
import { Router } from '@angular/router';
import { EvoPlanService } from './services/evo-plan.service';
import { Plan } from './models/plan';
import { Effort } from './models/effort';
import { Partner } from './models/partner';
import { EvoEffortService } from './services/evo-effort.service';
import { EvoPermissionService } from './services/evo-permission.service';
import { EvoPermissionUserService } from './services/evo-permission-user.service';
import { Permission } from './models/permission';
import { PermissionUser } from './models/permissionuser';

@Component({
  selector: 'app-evo-home',
  templateUrl: './evo-home.component.html',
  styleUrls: ['./evo-home.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class EvoHomeComponent implements OnInit {
  @ViewChild('gainedChartRef') gainedChartRef: any;
  @ViewChild('orderChartRef') orderChartRef: any;
  @ViewChild('avgSessionChartRef') avgSessionChartRef: any;
  @ViewChild('supportChartRef') supportChartRef: any;
  @ViewChild('salesChartRef') salesChartRef: any;



  // Public
  public data: any;
  public currentUser: any;
  public loading = false;
  public gainedChartoptions;
  public budgetChartoptions;
  public orderChartoptions;
  public avgsessionChartoptions;
  public supportChartoptions;
  public salesChartoptions;
  public revenueReportChartoptions;
  public isMenuToggled = true;

  // Private
  private $budgetStrokeColor2 = '#dcdae3';
  private $textMutedColor = '#b9b9c3';
  private $white = '#fff';
  private $textHeadingColor = '#5e5873';
  private $earningsStrokeColor2 = '#28c76f66';
  private $earningsStrokeColor3 = '#28c76f33';

  coreConfig: any;
  menu: any;
  defaultLanguage: 'tr';
  appLanguage: 'tr';
  currentMonth: any;

  userRole: string;
  userName: string;
  myId: number;

  private _unsubscribeAll: Subject<any>;
  statisticsBar: any;
  statisticsBarChartRef: any;
  statisticsLine: any;
  statisticsLineChartRef: any;
  earningChartoptions: any;
  earningChartRef: any;
  revenueReportChartRef: any;
  budgetChartRef: any;
  goalChartoptions: any;
  goalChartRef: any;
  ticketCount: number = 0;
  ticketCounts: number[] = [100, 200, 50];

  /**
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    public _coreConfigService: CoreConfigService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _dashboardService: DashboardService,

    private ticketService: EvoTicketService,
    private ticketMessageService: EvoTicketMessageService,
    private partnerUserService: EvoPartnerUserService,
    private ticketUserService: EvoTicketUserService,
    private planService: EvoPlanService,
    private effortService: EvoEffortService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,

    private userService: EvoUserService,
    private router: Router,
    private decodeService: DecodeService,
    private informationService: InformationService,
    private errorService: ErrorService,
  ) {
    const currentDateAsString = new Date().toISOString();
    this.currentMonth = this.getTurkishMonth(currentDateAsString);
    this._coreTranslationService.translate(menuTurkish);

    this.menu = menu;

    this._coreMenuService.register('main', this.menu);

    this._coreMenuService.setCurrentMenu('main');

    this._translateService.addLangs(['tr', 'tr', 'tr', 'tr']);

    this._translateService.setDefaultLang('tr');

    this._coreTranslationService.translate(menuTurkish);

    this._unsubscribeAll = new Subject();


    let ticketCountsForThisMonth: string;

    this.ticketService.getTicketCount().subscribe((res: any) => {
      this.ticketCounts = res.data;
      ticketCountsForThisMonth = this.ticketCounts[0].toString();
    });

    this.earningChartoptions = {
      chart: {
        type: 'donut',
        height: 120,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [this.ticketCounts[0], this.ticketCounts[0], this.ticketCounts[0]],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['Müşteri Talebi', 'Destek Talebi', 'Proje Talebi'],
      stroke: { width: 0 },
      colors: [this.$earningsStrokeColor2, this.$earningsStrokeColor3, colors.solid.success],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'Açılan Talepler',
                formatter: (w) => {
                  return ticketCountsForThisMonth;
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };
  }

  firstLevelRestriction: boolean;
  secondLevelRestriction: boolean;
  firstLevelRestrictionForActivity: boolean;
  secondLevelRestrictionForActivity: boolean;
  firstLevelRestrictionForEffort: boolean;
  secondLevelRestrictionForEffort: boolean;

  plans: Plan[] = [];
  efforts: Effort[] = [];
  tickets: Ticket[] = [];
  ticketMessages: any[] = [];
  ticketUsers: TicketUser[] = [];
  urgentTickets: TicketUser[] = [];
  newTicketUsers: TicketUser[] = [];
  newUrgentTicketUsers: TicketUser[] = [];

  selectedPriorities: Priority[] = [];
  selectedStatuses: Status[] = [];
  selectedEffortId: number;
  plannedDays: number[] = [];

  newTicketCount: number = 0;
  followedTickets: number = 0;
  ticketsWithHighPriority: number = 0;
  ticketsWithContinuingStatus: number = 0;
  ticketWithComplatedStatus: number = 0;
  ticketWithWaitingForAswerStatus: number = 0;

  pageNumberForUrgentTickets: number = 1;
  pageNumberForLastMessages: number = 1;
  pageNumberForEfforts: number = 1;

  plan: Plan = new Plan();
  user: User = new User();
  today: Date = new Date();
  effort: Effort = new Effort();
  permissions: Permission[] = [];
  permissionUsers: PermissionUser[] = [];
  partnerId: number = 0;


  ngOnInit(): void {
    this.today.setMinutes(this.today.getMinutes() + 60);
    this.today.setHours(this.today.getHours() - 1);
    this.getUserInformation();


    this.loading = true;
    this._dashboardService.onApiDataChanged.subscribe(response => {
      this.data = response;
    });
  }
  getUserInformation() {
    this.userName = this.decodeService.getUserName();
    this.userRole = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    this.userService.get(this.myId).subscribe((res: any) => {
      this.user = res.data;
    });
    this.getPermissions();
  }
  getPermissions() {
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
      this.checkPermissionUsers();
    });
  }
  checkPermissionUsers() {
    this.permissionUserService.getPermissionUserListByUserId(this.myId).subscribe((items: any) => {
      this.permissionUsers = items.data;
      this.updateRestrictions();
    });
  }

  updateRestrictions() {
    this.firstLevelRestriction = this.checkPermission(1);
    this.secondLevelRestriction = this.checkPermission(2);
    this.firstLevelRestrictionForActivity = this.checkPermission(5);
    this.secondLevelRestrictionForActivity = this.checkPermission(6);
    this.firstLevelRestrictionForEffort = this.checkPermission(8);
    this.secondLevelRestrictionForEffort = this.checkPermission(9);

    if (!this.firstLevelRestriction && !this.secondLevelRestriction) {
      if (this.userRole === "Admin" || this.userRole === "Consultant" || this.userRole === "ConsultantUser") {
        this.handleAdminConsultantLogic();
      } else if (this.userRole === "Customer" || this.userRole === "CustomerUser") {
        this.handleCustomerLogic();


      }
    } else if (this.firstLevelRestriction && !this.secondLevelRestriction) {
      this.handleFirstLevelRestrictionLogic();
    } else if (this.secondLevelRestriction && (this.userRole != "Customer" && this.userRole != "CustomerUser")) {
      this.handleSecondLevelRestrictionLogic();
    }
    else {
      this.handleResctrictedCustomer();
    }
  }

  checkPermission(permissionId: number) {
    return this.permissionUsers.some(user => user.permissionId === permissionId);
  }

  handleAdminConsultantLogic() {

    this.listofTicketMessages(1);
    this.getNearestPlan();
    this.getPlannedDays();
    this.listofNewTickets();
    this.listofTicketUsers();
  }

  handleCustomerLogic() {

    this.getCustomersParterId();

    this.listofTicketMessagesForPartner(1);
    this.listofWatinigAnswerTickets(1);
    this.listofWaitingAcceptionEfforts(1);
    //this.listofNewTickets();
    this.listofTicketUsersForCustomers();
  }
  handleResctrictedCustomer() {

    this.listofTicketMessagesForPartner(1);
    this.listofWatinigAnswerTickets(1);
    this.listofWaitingAcceptionEfforts(1);
    this.listofNewTickets();
    this.listofTicketUsers();
  }

  handleFirstLevelRestrictionLogic() {
    // this.userService.get(this.myId).subscribe((items: any) => {
    //   this.partnerUserService.getMyPartnerTicketList(items.data.partnerId).subscribe((res: any) => {
    //     this.tickets = res.data;
    //     this.informationService.list(res.message);
    //   }, (err: any) => {
    //     this.errorService.errorHandler(err);
    //   });
    // });
    this.getCustomersParterId();

    this.listofTicketMessages(1);
    this.listofTicketUsers();
    this.getNearestPlan();
    this.getPlannedDays();
  }

  handleSecondLevelRestrictionLogic() {

    this.loadTicketUsers(this.ticketUserService.getMyTicketUserList(this.myId));
    this.listofTicketMessages(1);
    this.listofTicketUsers();
    this.getNearestPlan();
    this.getPlannedDays();
  }
 

  getCustomersParterId(){
    this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
      this.partnerId = res.data.partnerId;
      this.listCustomerTickets();
    });
  }
  listofNewTickets() {
    if (!this.firstLevelRestriction && !this.secondLevelRestriction) {
      if (this.userRole === "Admin" || this.userRole === "Consultant" || this.userRole === "ConsultantUser") {
        this.listAdminConsultantTickets();
      } else if (this.userRole === "Customer" || this.userRole === "CustomerUser") {
        this.listCustomerTickets();
      }
    }
    else if (this.firstLevelRestriction && !this.secondLevelRestriction) {
      this.listFirstLevelRestrictionTickets();
    }
  }

  listAdminConsultantTickets() {
    this.ticketService.getList().subscribe((items: any) => {
      this.tickets = items.data;
      this.informationService.list(items.message);
    }, (err: any) => {
      this.errorService.errorHandler(err);
    });
  }

  listCustomerTickets() {
    this.partnerUserService.getMyPartnerTicketList(this.partnerId).subscribe((res: any) => {
      this.tickets = res.data;
      this.informationService.list(res.message);
    }, (err: any) => {
      this.errorService.errorHandler(err);
    });
  }

  listFirstLevelRestrictionTickets() {
    this.userService.get(this.myId).subscribe((items: any) => {
      this.partnerUserService.getMyPartnerTicketList(items.data.partnerId).subscribe((res: any) => {
        this.tickets = res.data;
        this.informationService.list(res.message);
      }, (err: any) => {
        this.errorService.errorHandler(err);
      });
    });
  }

  listofTicketUsers() {
    if (!this.firstLevelRestriction && !this.secondLevelRestriction) {
      if (this.userRole === "Admin" || this.userRole === "Consultant" || this.userRole === "ConsultantUser") {
        this.loadTicketUsers(this.ticketUserService.getList());
      } else if (this.userRole === "Customer" || this.userRole === "CustomerUser") {
        this.loadTicketUsers(this.partnerUserService.getMyPartnerTicketUserList(this.partnerId));
      }
    } else if (this.firstLevelRestriction && !this.secondLevelRestriction) {
      this.loadTicketUsers(this.ticketUserService.restrictionForExternalSourceTicketUsers(this.myId));
    } else if (this.secondLevelRestriction) {
      this.loadTicketUsers(this.ticketUserService.getMyTicketUserList(this.myId));
    }
  }



  nextPageTicketMessages() {
    this.pageNumberForLastMessages++;
    this.listofTicketMessages(this.pageNumberForLastMessages);
  }
  nextPageForPartnersTicketMessages() {
    this.pageNumberForLastMessages++;
    this.listofTicketMessagesForPartner(this.pageNumberForLastMessages);
  }

  previousPageTicketMessages() {
    if (this.pageNumberForLastMessages > 1) {
      this.pageNumberForLastMessages--;
      this.listofTicketMessages(this.pageNumberForLastMessages);
    }
  }

  previousPageForPartnersTicketMessages() {
    if (this.pageNumberForLastMessages > 1) {
      this.pageNumberForLastMessages--;
      this.listofTicketMessagesForPartner(this.pageNumberForLastMessages);
    }
  }
  listofTicketMessagesForPartner(pageNumber: number) {

    this.partnerUserService.getLastestMessagesForPartnerWithPermission(pageNumber, this.myId, this.secondLevelRestriction).subscribe((items: any) => {
      let data = items.data;
      if (items.success == true && data.length == 0) {
        this.pageNumberForLastMessages--;
      }
      else {
        this.ticketMessages = items.data;
      }
    }
    );
  }
  listofTicketMessages(pageNumber: number) {
    this.ticketMessageService.getLastestListWithPermissionDto(pageNumber, this.myId, this.firstLevelRestriction, this.secondLevelRestriction).subscribe((items: any) => {
      let data = items.data;
      if (items.success == true && data.length == 0) {
        this.pageNumberForLastMessages--;
      }
      else {
        this.ticketMessages = items.data;
      }
    }
    );
  }
  listofWatinigAnswerTickets(pageNumber: number) {
    this.partnerUserService.getWaitingAnswerTicketListForPartnerWithPermission(pageNumber, this.myId, this.secondLevelRestriction).subscribe((items: any) => {
      let data = items.data;
      if (items.success == true && data.length == 0) {
        this.pageNumberForUrgentTickets--;
      }

      else {
        this.urgentTickets = items.data;
        this.newUrgentTicketUsers = Object.values(this.urgentTickets.reduce((acc, ticket) => {
          if (!acc[ticket.trackingNumber]) {
            acc[ticket.trackingNumber] = [];
          }
          acc[ticket.trackingNumber].push(ticket);
          return acc;
        }, {}));
      }
    },
      (err: any) => {
        this.errorService.errorHandler(err);
      }
    );
  }

  nextPageUrgentTickets(pageNumber: number) {
    this.pageNumberForUrgentTickets = pageNumber + 1;
    this.listofUrgentTickets(this.pageNumberForUrgentTickets);
  }
  nextPagePartnersUrgentTickets(pageNumber: number) {
    this.pageNumberForUrgentTickets = pageNumber + 1;
    this.listofWatinigAnswerTickets(this.pageNumberForUrgentTickets);
  }

  previousPageUrgentTickets(pageNumber: number) {
    if (pageNumber > 1) {
      this.pageNumberForUrgentTickets = pageNumber - 1;
      this.listofUrgentTickets(this.pageNumberForUrgentTickets);
    }
  }
  previousPagePartnersUrgentTickets(pageNumber: number) {
    if (pageNumber > 1) {
      this.pageNumberForUrgentTickets = pageNumber - 1;
      this.listofWatinigAnswerTickets(this.pageNumberForUrgentTickets);
    }
  }
  listofUrgentTickets(pageNumber: number) {
    this.ticketUserService.getUrgentTicketUserListWithPermissionDto(pageNumber, this.myId, this.firstLevelRestriction, this.secondLevelRestriction).subscribe((items: any) => {
      let data = items.data;
      if (items.success == true && data.length == 0) {
        this.pageNumberForUrgentTickets--;
      }

      else {
        this.urgentTickets = items.data;
        this.newUrgentTicketUsers = Object.values(this.urgentTickets.reduce((acc, ticket) => {
          if (!acc[ticket.trackingNumber]) {
            acc[ticket.trackingNumber] = [];
          }
          acc[ticket.trackingNumber].push(ticket);
          return acc;
        }, {}));
      }
    },
      (err: any) => {
        this.errorService.errorHandler(err);
      }
    );
  }

  listofWaitingAcceptionEfforts(pageNumber: number) {
    this.partnerUserService.getWaitingAcceptionEffortListWithPermission(pageNumber, this.myId, this.secondLevelRestrictionForEffort).subscribe((items: any) => {
      let data = items.data;
      if (items.success == true && data.length == 0) {
        this.pageNumberForEfforts--;
      } else {
        this.efforts = data;
      }
    });
  }
  nextPageForEfforts(pageNumber: number) {
    this.pageNumberForEfforts = pageNumber + 1;
    this.listofWaitingAcceptionEfforts(this.pageNumberForEfforts);
  }
  previousPageForEfforts(pageNumber: number) {
    if (pageNumber > 1) {
      this.pageNumberForEfforts = pageNumber - 1;
      this.listofWaitingAcceptionEfforts(this.pageNumberForEfforts);
    }
  }
  getNearestPlan() {
    this.planService.getNearestPlan(this.myId).subscribe((res: any) => {
      this.plan = res.data;
    })
  }
  getPlannedDays() {
    this.planService.getPlannedDays(this.myId).subscribe((res: any) => {
      this.plannedDays = res.data;
    })
  }


  getAcceptedEffort(id: number) {
    this.effortService.get(id).subscribe((res: any) => {
      this.effort = res.data;
      this.effort.statusId = 1;
      this.effort.partnerUserId = this.myId;
      this.effort.updatedBy = this.myId;
      this.effort.deletedBy = 0;
      this.effortService.updateStatus(this.effort).subscribe((res: any) => {
        this.informationService.update(res.message);
      }, (err: any) => {
        this.errorService.errorHandler(err);
      }, () => {
        this.listofWaitingAcceptionEfforts(this.pageNumberForEfforts);
      });

    });
  }
  getDeniedEffort(id: number) {
    this.effortService.get(id).subscribe((res: any) => {
      this.effort = res.data;
      this.effort.statusId = 3;
      this.effort.partnerUserId = this.myId;
      this.effort.updatedBy = this.myId;
      this.effort.deletedBy = 0;
      this.effortService.updateStatus(this.effort).subscribe((res: any) => {
        this.informationService.update(res.message);
      }, (err: any) => {
        this.errorService.errorHandler(err);
      }, () => {
        this.listofWaitingAcceptionEfforts(this.pageNumberForEfforts);
      });

    });
  }

  private loadTicketUsers(observable: Observable<any>) {
    observable.subscribe(
      (items: any) => {
        this.ticketUsers = items.data;
        this.newTicketUsers = Object.values(this.ticketUsers.reduce((acc, ticket) => {
          if (!acc[ticket.trackingNumber]) {
            acc[ticket.trackingNumber] = [];
          }
          acc[ticket.trackingNumber].push(ticket);
          return acc;
        }, {}));
        this.ticketsWithContinuingStatus = this.newTicketUsers.filter((group: any) => group.some(item => item.statusId === 2)).length;
        this.ticketWithComplatedStatus = this.newTicketUsers.filter((group: any) => group.some(item => item.statusId === 4)).length;
        this.ticketWithWaitingForAswerStatus = this.newTicketUsers.filter((group: any) => group.some(item => item.statusId === 8)).length;
        this.followedTickets = this.newTicketUsers.filter((group: any) => group.some(item => item.userId === this.myId)).length;
        //console.log(this.newTicketUsers);
      },
      (err: any) => {
        this.errorService.errorHandler(err);
      }
    );
  }
  listofTicketUsersForCustomers() {
  
    this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
      this.partnerUserService.getMyPartnerTicketUserList(res.data.partnerId).subscribe((res: any) => {
        this.ticketUsers = res.data;
        this.newTicketUsers = Object.values(this.ticketUsers.reduce((acc, ticket) => {
          if (!acc[ticket.trackingNumber]) {
            acc[ticket.trackingNumber] = [];
          }
          acc[ticket.trackingNumber].push(ticket);
          return acc;
        }, {}));
        this.ticketsWithContinuingStatus = this.newTicketUsers.filter((group: any) => group.some(item => item.statusId === 2)).length;
        this.ticketWithComplatedStatus = this.newTicketUsers.filter((group: any) => group.some(item => item.statusId === 4)).length;
        this.ticketWithWaitingForAswerStatus = this.newTicketUsers.filter((group: any) => group.some(item => item.statusId === 8)).length;
        this.followedTickets = this.newTicketUsers.filter((group: any) => group.some(item => item.userId === this.myId)).length;

      }, (err: any) => {
        this.errorService.errorHandler(err)
      });
    });
  }

  handleError(err: any) {
    this.errorService.errorHandler(err);
  }

  onIconClicked(filter: any) {
    localStorage.setItem('filter', JSON.stringify(filter));
    this.router.navigate(['/home/tickets']);

  }



  getTurkishDay(dateString: string): string {
    const date = new Date(dateString);
    const days = ['Pzr', 'Pzt', 'Sal', 'Çrş', 'Prş', 'Cum', 'Cmt'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }

  getTurkishMonth(dateString: string): string {
    const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    return months[monthIndex];
  }



  getInitials(name: string) {
    const userName = name;
    const firstInitial = userName.charAt(0);
    const lastInitial = userName.lastIndexOf(' ') > -1
      ? userName.substring(userName.lastIndexOf(' ') + 1, userName.lastIndexOf(' ') + 2)
      : '';
    return firstInitial + lastInitial;
  }
  ngAfterViewInit() {
    this._coreConfigService.getConfig().subscribe(config => {
      if (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) {
        setTimeout(() => {
          this.isMenuToggled = false;
          this.budgetChartoptions.chart.width = this.budgetChartRef?.nativeElement.offsetWidth;
        }, 1000);
      }
    });
  }
}
