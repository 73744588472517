import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CalendarOptions, EventClickArg, eventTupleToStore } from '@fullcalendar/angular';
import { EventRef } from 'app/evo/models/calender';
import { takeUntil } from 'rxjs/operators';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { Observable, Subject } from 'rxjs';
import { Partner } from 'app/evo/models/partner';
import { User } from 'app/evo/models/user';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { FormControl } from '@angular/forms';
import { Plan } from 'app/evo/models/plan';
import { InformationService } from 'app/evo/services/information.service';
import { ErrorService } from 'app/evo/services/error.service';
import { PlanType } from 'app/evo/models/plantype';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as menuTurkish } from 'app/menu/i18n/tr';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Router } from '@angular/router';
import { EvoTripPlanService } from 'app/evo/services/evo-trip-plan.service';
import { TripPlan } from 'app/evo/models/tripplan';
import { TripDetail } from 'app/evo/models/tripdetails';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-evo-plandetail',
  templateUrl: './evo-plandetail.component.html',
})
export class EvoPlanDetailComponent implements OnInit {

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  public event: Plan;
  newEvent: any;
  fromDate: Date;
  toDate: Date;
  saturdayPlans = false;
  sundayPlans = false;
  public isDataEmpty;

  today: Date = new Date();

  public calendarRef = [];
  public tempRef = [];
  public checkAll = true;

  public slideoutShow = false
  public events = [];

  startedDates: any[] = [];
  endedDates: any[] = [];

  public calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: 'userName, prev, next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    locale: 'tr',
    initialView: 'dayGridMonth',
    initialEvents: this.events,
    weekends: true,
    editable: true,
    eventResizableFromStart: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: 2,
    navLinks: true,
    eventClick: this.handleUpdateEventClick.bind(this),
    eventClassNames: this.getColorWithType.bind(this),
    select: this.handleDateSelect.bind(this),
    firstDay: 1,
    buttonText: {
      today: 'Bugün',
      month: 'Ay',
      week: 'Hafta',
      day: 'Gün',
      list: 'Liste',
    },
  };

  clicked: boolean = false;
  addMoreDetails: boolean = false;
  addTripDetails: boolean = true;

  planIsInPlace: boolean = false;
  canDelete: boolean = false;

  planIsOutOfTown: boolean = false;
  planHaveBusDetail: boolean = false;
  planHaveHotelDetail: boolean = false;
  planHaveFlightDetail: boolean = false;

  busDetail: boolean = false;
  flightDetail: boolean = false;
  hotelDetail: boolean = false;

  deletedPlanId: number;
  selectedTypes: any[] = [];
  userRole: string;
  myId: number;

  type: PlanType = new PlanType();
  types: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzin" },
  ];
  typeForFilter: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzin" },
    { id: 4, name: "Resmi Tatiller" },
  ];
  tripDetails: TripDetail[] = [
    { id: 1, name: "Otobüs" },
    { id: 2, name: "Uçak" },
    { id: 3, name: "Otel Rezarvasyonu" },
  ];
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private transactionService: EvoTransactionService,

    private informationService: InformationService,
    private errorService: ErrorService,
    private _coreSidebarService: CoreSidebarService,
    private planService: EvoPlanService,
    private tripPlanService: EvoTripPlanService,
    private decodeService: DecodeService,
    private router: Router,
  ) {
    this._unsubscribeAll = new Subject();
    this.today.setDate(this.today.getDate());
  }
  partners: Partner[] = [];
  selectedUsers: User[] = [];
  tripPlans: TripPlan[] = [];
  users: User[] = [];
  selectedUsersForPlan: any[] = [];
  usersForPlan: User[] = [];
  selectedUserIds: any[] = [];

  dropdownSettings: any = {};

  partner: Partner = new Partner();
  tripPlan: TripPlan = new TripPlan();
  plan: Plan = new Plan();
  user: User = new User();

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'firstName',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.userRole = this.decodeService.getRole();

    if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    this.myId = this.decodeService.getUserId();
    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });

    this.type.id = 0;
    this._coreTranslationService.translate(menuTurkish);

    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });
    this.userService.getListForFilter().subscribe((res: any) => {
      this.users = res.data;
      this.usersForPlan = res.data;

    });
    this.list();
    this.getDepartmentList();
  }
  public selectMulti: Observable<any[]>;
  public selectMultiSelected = [{ firstName: 'Cafer Emre Solmaz' }];

  list() {
    if (this.userRole == "Admin") {
      this.planService.getList().subscribe((res: any) => {
        this.events = res.data;

        this.calendarOptions.events = res.data.map((event) => {
          if (event.partnerId != 0 || event.userId != 0) {
            return {
              title: event.userName + " - " + event.title + " - " + event.partnerName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }

          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,

              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
    if (this.userRole == "Consultant") {
      this.planService.getList().subscribe((res: any) => {
        this.events = res.data;
        this.calendarOptions.events = res.data.map((event) => {
          if (event.partnerId != 0 || event.userId != 0) {
            return {
              title: event.userName + " - " + event.title + " - " + event.partnerName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,

              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
    if (this.userRole == "ConsultantUser") {
      this.planService.getListByUserId(this.myId).subscribe((res: any) => {
        this.events = res.data;

        this.calendarOptions.events = res.data.map((event) => {
          if (event.partnerId != 0 || event.userId != 0) {
            return {
              title: event.title + " - " + event.partnerName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,

              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
  }


  toggleEventSidebar() {
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
  }

  AddEvent() {
    this.plan = new Plan();
    this.toggleEventSidebar();
    this._coreSidebarService.getSidebarRegistry('calendar-main-sidebar').toggleOpen();
  }

  getColorWithType(data: any) {
    if (data.event._def.extendedProps.calendar == 1) {
      return `bg-light-danger`;
    }
    else if (data.event._def.extendedProps.calendar == 2) {
      return `bg-light-primary`;
    }
    else if (data.event._def.extendedProps.calendar == 3) {
      return `bg-light-success`;
    }
    else if (data.event._def.extendedProps.calendar == 4) {
      return `bg-light-info`;
    }
  }

  populateMultiselect(user: User) {
    var userSelectId = { id: user.id, firstName: user.firstName + " " + user.lastName };
    this.selectedUserIds.push(userSelectId);
  }


  handleUpdateEventClick(eventRef: EventClickArg) {
    this.fromDate = new Date(new Date(eventRef.event._instance.range.start.getFullYear(), eventRef.event._instance.range.start.getMonth(), eventRef.event._instance.range.start.getDate(), eventRef.event._instance.range.start.getHours(), eventRef.event._instance.range.start.getMinutes(), 0).toISOString().slice(0, 16));
    this.toDate = new Date(new Date(eventRef.event._instance.range.end.getFullYear(), eventRef.event._instance.range.end.getMonth(), eventRef.event._instance.range.end.getDate(), eventRef.event._instance.range.end.getHours(), eventRef.event._instance.range.end.getMinutes(), 0).toISOString().slice(0, 16));

    this.resetAllBooleans();
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
    this.requiredPartnerSelection = false;
    this.selectedUserIds = [];
    this.planService.get(parseInt(eventRef.event.id)).subscribe((res: any) => {
      this.plan = res.data;
      var user = this.users.find(obj => obj.id === this.plan.userId);
      var userSelectId = { id: user.id, firstName: user.firstName };
      this.selectedUserIds = this.selectedUserIds.concat(userSelectId);
      this.canDeletePlan();
      this.markLocationType(this.plan);
      this.tripPlanService.getListByPlanId(this.plan.id).subscribe((res: any) => {
        this.tripPlans = res.data;
        if (this.tripPlans[0].tripTypeId != 1) {
          this.tripPlan = this.tripPlans[0];
          this.planIsOutOfTown = true;
          this.markDetails(this.tripPlans);
        }
      });
    });
  }
  selectedPlansEndDate: Date = new Date();

  canDeletePlan() {
    const todayWithoutTime = new Date(this.today);
    todayWithoutTime.setHours(0, 0, 0, 0);

    const selectedEndDateWithoutTime = new Date(this.plan.endDate);
    selectedEndDateWithoutTime.setHours(0, 0, 0, 0);

    if ((selectedEndDateWithoutTime > todayWithoutTime && this.plan.userId == this.myId) || this.userRole == 'Admin') {
      this.canDelete = true;
    } else {
      this.canDelete = false;
    }
  }



  markLocationType(data: any) {
    if (data.locationTypeId == 3) {
      this.planIsInPlace = true;
    }
    else {
      this.planIsInPlace = false;
    }
  }
  markDetails(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].tripDetailId == 1) {
        this.planHaveBusDetail = true;
      }
      if (data[i].tripDetailId == 2) {
        this.planHaveFlightDetail = true;
      }
      if (data[i].tripDetailId == 3) {
        this.planHaveHotelDetail = true;
      }
    }
    this.busDetail = this.planHaveBusDetail;
    this.flightDetail = this.planHaveFlightDetail;
    this.hotelDetail = this.planHaveHotelDetail;
  }

  update() {
    if (this.saturdayPlans == true || this.sundayPlans == true) {
      this.updatePartOfPlan(this.startedDates, this.endedDates);
    }

    else {
      this.plan.startDate = this.fromDate;
      this.plan.endDate = this.toDate;

      if (this.plan.startDate > this.plan.endDate) {
        let temp;
        temp = this.plan.startDate;
        this.plan.startDate = this.plan.endDate;
        this.plan.endDate = temp;
      }
      this.updateTripPlans();
      this.updatePlanLocationType();
      this.plan.updatedBy = this.myId;
      this.plan.deletedBy = 0;
      this.planService.update(this.plan).subscribe((res: any) => {
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        window.location.reload();
      });
    }
  }
  updatePlanLocationType() {
    if (this.planIsInPlace == true)
      this.plan.locationTypeId = 3;

    else
      this.plan.locationTypeId = 4;
  }
  updateTripPlans() {
    const tripDetailsToAdd = [];

    if (this.busDetail !== this.planHaveBusDetail) {
      const busTripPlansToUpdate = this.tripPlans.filter(item => item.tripDetailId === 1);
      if (!this.planHaveBusDetail)
        this.deleteTripPlans(busTripPlansToUpdate);
      else
        tripDetailsToAdd.push(1);
    }

    if (this.flightDetail !== this.planHaveFlightDetail) {
      const flightTripPlansToUpdate = this.tripPlans.filter(item => item.tripDetailId === 2);
      if (!this.planHaveFlightDetail)
        this.deleteTripPlans(flightTripPlansToUpdate);
      else
        tripDetailsToAdd.push(2);
    }

    if (this.hotelDetail !== this.planHaveHotelDetail) {
      const hotelTripPlansToUpdate = this.tripPlans.filter(item => item.tripDetailId === 3);
      if (!this.planHaveHotelDetail)
        this.deleteTripPlans(hotelTripPlansToUpdate);
      else
        tripDetailsToAdd.push(3);
    }

    if (!this.planIsOutOfTown) {
      this.deleteTripPlans(this.tripPlans);
    }
    if (this.planIsOutOfTown && this.tripPlans.length == 0) {
      this.addTripPlan(this.plan.id, null);
    }

    tripDetailsToAdd.forEach(tripDetailId => {
      this.addTripPlan(this.plan.id, tripDetailId);
    });
  }
  updatePartOfPlan(startDates: any, endDates: any) {
    for (let i = 0; i < startDates.length; i++) {

      if (this.startedDates[i] == this.endedDates[i]) {
        this.endedDates[i] = new Date(this.endedDates[i].setHours(23, 59, 59));
      }
      this.startedDates[i].setHours(0, 0, 0);
      this.endedDates[i].setHours(23, 59, 59);

      this.updateTripPlans();
      this.plan.startDate = this.startedDates[i];
      this.plan.endDate = this.endedDates[i];

      this.planService.update(this.plan).subscribe((res: any) => {
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        window.location.reload();
      });
    }
  }


  deleteTripPlans(tripPlansToUpdate) {
    tripPlansToUpdate.forEach(element => {
      this.tripPlanService.delete(element.id).subscribe((res: any) => {
      });
    });
  }

  addTripPlan(planId: number, tripDetailId: number) {
    this.tripPlan = {
      id: 0,
      planId: planId,
      tripDetailId: tripDetailId,
      tripTypeId: 2,
      planTitle: "",
      planDescription: "",
      planUserId: 0,
      planUserName: "",
      planTypeId: 0,
      planTypeName: "",
      planPartnerId: 0,
      planPartnerName: "",
      planStartDate: new Date(),
      planEndDate: new Date(),

      tripTypeName: "",
      tripDetailName: ""
    };
    this.tripPlanService.add(this.tripPlan).subscribe((res: any) => {
    });
  }


  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }


  handleDateSelect(eventRef, title, description) {
    this.plan = new Plan();
    this.newEvent = new Plan();
    this.selectedUserIds = [];
    this.resetAllBooleans();

    this.newEvent.start = eventRef.start;
    this.newEvent.end = eventRef.end;
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
    this.planService.onCurrentEventChange.next(this.newEvent);

    this.fromDate = new Date(this.newEvent.start.getFullYear(), this.newEvent.start.getMonth(), this.newEvent.start.getDate(), 0, 0, 0);
    this.toDate = new Date(this.newEvent.end.getFullYear(), this.newEvent.end.getMonth(), this.newEvent.end.getDate() - 1, 23, 59, 0);

    this.requiredPartnerSelection = false;
  }
  resetAllBooleans() {
    this.planIsOutOfTown = false;
    this.planHaveBusDetail = false;
    this.planHaveFlightDetail = false;
    this.planHaveHotelDetail = false;
  }
  includeWeekendPlans() {
    if (this.plan.startDate > this.plan.endDate) {
      let temp;
      temp = this.plan.startDate;
      this.plan.startDate = this.plan.endDate;
      this.plan.endDate = temp;
    }

    if (this.saturdayPlans == true && this.sundayPlans == false) {

      const savedDates = [];
      const startDates = [];
      const endDates = [];

      const fromDate = this.fromDate;
      const toDate = this.toDate;

      let startDate;

      while (fromDate <= toDate) {
        const dayOfWeek = fromDate.getDay();

        if (dayOfWeek !== 6) {
          savedDates.push(new Date(fromDate));
          if (!startDate) {
            startDate = new Date(fromDate);
          }
        } else {
          if (savedDates.length > 0) {
            startDates.push(startDate);
            endDates.push(savedDates[savedDates.length - 1]);
          }
          startDate = null;
          savedDates.length = 0;
        }

        fromDate.setDate(fromDate.getDate() + 1);
      }

      if (savedDates.length > 0) {
        startDates.push(startDate);
        endDates.push(savedDates[savedDates.length - 1]);
      }
      this.startedDates = startDates;
      this.endedDates = endDates;
    }

    if (this.sundayPlans == true && this.saturdayPlans == false) {
      const savedDates = [];
      const startDates = [];
      const endDates = [];

      const fromDate = this.fromDate;
      const toDate = this.toDate;

      let startDate;

      while (fromDate <= toDate) {
        const dayOfWeek = fromDate.getDay();

        if (dayOfWeek !== 0) {
          savedDates.push(new Date(fromDate));
          if (!startDate) {
            startDate = new Date(fromDate);
          }
        } else {
          if (savedDates.length > 0) {
            startDates.push(startDate);
            endDates.push(savedDates[savedDates.length - 1]);
          }
          startDate = null;
          savedDates.length = 0;
        }

        fromDate.setDate(fromDate.getDate() + 1);
      }

      if (savedDates.length > 0) {
        startDates.push(startDate);
        endDates.push(savedDates[savedDates.length - 1]);
      }

      this.startedDates = startDates;
      this.endedDates = endDates;
    }

    if (this.sundayPlans == true && this.saturdayPlans == true) {
      const savedDates = [];
      const startDates = [];
      const endDates = [];

      const fromDate = this.fromDate;
      const toDate = this.toDate;

      let startDate;

      while (fromDate <= toDate) {
        const dayOfWeek = fromDate.getDay();

        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
          savedDates.push(new Date(fromDate));
          if (!startDate) {
            startDate = new Date(fromDate);
          }
        } else {
          if (savedDates.length > 0) {
            startDates.push(startDate);
            endDates.push(savedDates[savedDates.length - 1]);
          }
          startDate = null;
          savedDates.length = 0;
        }

        fromDate.setDate(fromDate.getDate() + 1);
      }

      if (savedDates.length > 0) {
        startDates.push(startDate);
        endDates.push(savedDates[savedDates.length - 1]);
      }

      this.startedDates = startDates;
      this.endedDates = endDates;
    }
  }

  addUserToPlan() {
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').close();
  }
  // selectMultiUser(){
  //   this.selectedUsersForPlan.forEach(element => {
  //     this.selectedUserIds.push(element.id);
  //     console.log(this.selectedUserIds);
  //   });

  // }

  urlEncodeForGoogle(url: string): string {
    const unreservedChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-.~";
    let result = '';

    for (const symbol of url) {
      if (unreservedChars.indexOf(symbol) !== -1) {
        result += symbol;
      } else {
        result += '%' + symbol.charCodeAt(0).toString(16).toUpperCase();
      }
    }

    return result;
  }

  authToGoogle() {
    var redirectURL = environment.apiUrl + "AuthorizeGoogle";
    var prompt = "consent"
    var response_type = "code";
    var clientID = environment.googleClientId;
    var scope = "https://www.googleapis.com/auth/calendar";
    var access_type = "offline";
    var state = JSON.stringify({ userId: this.myId });
    var redirect_uri_encode = this.urlEncodeForGoogle(redirectURL);
    var scopeURL1 = "https://accounts.google.com/o/oauth2/auth?redirect_uri=" + redirect_uri_encode + "&prompt=" + prompt + "&response_type=" + response_type + "&client_id=" + clientID + "&scope=" + scope + "&access_type=" + access_type + "&state=" + state;
    window.location.href = scopeURL1;
  }

  syncWithGoogle() {

    this.planService.checkGoogleAuth(this.myId).subscribe((result: any) => {
      var googleAuthInfo = result.data;
      if (googleAuthInfo != null) {

        var myEvents = null;

        if (this.userRole == "Admin") {
          myEvents = this.events;
        }
        else {
          myEvents = this.events.filter(x => x.userId = this.myId);
        }


        this.planService.getFromGoogleCalendar(this.myId).subscribe(((res: any) => {
          var google_data = res;

          myEvents.forEach((calendar_events) => {

            var calendarSyncData = google_data.items.find(x => x.summary == calendar_events.title && x.start.dateTime.slice(0, 19) == calendar_events.startDate && x.end.dateTime.slice(0, 19) == calendar_events.endDate && x.description == calendar_events.description);

            if (calendarSyncData == null) {
              this.planService.addToGoogleCalendar(this.myId, calendar_events).subscribe((res: any) => {

              });
            }
          })


          google_data.items.forEach((event_item) => {

            var start_date = event_item.start.dateTime.slice(0, 19);
            var end_date = event_item.end.dateTime.slice(0, 19);
            var title = event_item.summary;
            var description = event_item.description;

            var googleSyncData = myEvents.find(x => x.title == title && x.startDate == start_date && x.endDate == end_date && x.description == description);

            if (googleSyncData == null) {

              this.plan = {
                id: 0,
                userId: this.myId,
                partnerId: null,
                startDate: start_date,
                endDate: end_date,
                title: title,
                description: description,
                typeId: 1,
                userName: "",
                partnerName: "",
                planTypeName: "",
                locationTypeId: 3,
                locationTypeName: "",
                createdBy: this.myId,
                updatedBy: 0,
                deletedBy: 0,
              };


              this.planService.add(this.plan).subscribe((res: any) => {
                this.informationService.add(res.message);
              }, (err) => {
                this.errorService.errorHandler(err);
              });

            }
          })

          this.informationService.add("Senkronizasyon başarıyla tamamlandı.");
          setTimeout(() => {
            window.location.reload();
          }, 1500)

        }), error => {
          this.planService.deleteGoogleAuth(this.myId).subscribe(() => {

          })

          this.informationService.delete("Lütfen önce Google hesabınıza giriş yapın.");

          setTimeout(() => {
            this.authToGoogle();

          }, 1500);


        })

      }
      else {

        this.informationService.delete("Lütfen önce Google hesabınıza giriş yapın.");

        setTimeout(() => {
          this.authToGoogle();
        }, 1500);

      }

    })



  }


  add() {
    // console.log(this.selectedUsersForPlan);
    if (this.saturdayPlans == true || this.sundayPlans == true) {
      this.addPartOfPlan(this.startedDates, this.endedDates);
    }
    else {
      let formDate = this.fromDate;
      let toDate = this.toDate;

      this.fromDate.setHours(this.fromDate.getHours() + 3);
      this.toDate.setHours(this.toDate.getHours() + 3);
      
      if (this.userRole == "ConsultantUser") {
        this.selectedUserIds = [];
        this.selectedUserIds.push({ id: this.myId, firstName: "default" });
      }
      for (let i = 0; i < this.selectedUserIds.length; i++) {

        this.plan = {
          id: 0,
          userId: this.userRole == "ConsultantUser" ? this.myId : this.selectedUserIds[i].id,
          // userId:this.userRole == "ConsultantUser" ? this.myId : this.plan.userId,
          partnerId: this.plan.partnerId,
          startDate: formDate,
          endDate: toDate,
          title: this.plan.title,
          description: this.plan.description,
          typeId: this.plan.typeId,
          userName: "",
          partnerName: "",
          planTypeName: "",
          locationTypeId: this.planIsInPlace == true ? 3 : 4,
          locationTypeName: "",
          createdBy: this.myId,
          updatedBy: 0,
          deletedBy: 0,
        };

        this.planService.add(this.plan).subscribe((res: any) => {
          this.informationService.add(res.message);
          if (this.planIsOutOfTown === true) {
            const tripDetailsToPlan = [];

            if (this.planHaveBusDetail === true) {
              tripDetailsToPlan.push(this.tripDetails[0].id);
            }
            if (this.planHaveFlightDetail === true) {
              tripDetailsToPlan.push(this.tripDetails[1].id);
            }
            if (this.planHaveHotelDetail === true) {
              tripDetailsToPlan.push(this.tripDetails[2].id);
            }
            tripDetailsToPlan.forEach((tripDetailId) => {
              this.addTripPlan(res.data.id, tripDetailId);
            });
          }
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          window.location.reload();
        });
      }
    }
  }
  addPartOfPlan(startDates: any, endDates: any) {
    for (let i = 0; i < startDates.length; i++) {

      if (this.startedDates[i] == this.endedDates[i]) {
        this.endedDates[i] = new Date(this.endedDates[i].setHours(23, 59, 59));
      }
      this.startedDates[i].setHours(0, 0, 0);
      this.endedDates[i].setHours(23, 59, 59);

      let plan = new Plan();
      if (this.userRole == "ConsultantUser") {
        this.selectedUserIds = [];
        this.selectedUserIds.push({ id: this.myId, firstName: "default" });
      }
      for (let i = 0; i < this.selectedUserIds.length; i++) {

        plan = {
          id: 0,
          userId: this.userRole == "ConsultantUser" ? this.myId : this.selectedUserIds[i].id,
          partnerId: this.partner.id,
          startDate: startDates[i],
          endDate: endDates[i],
          title: this.plan.title,
          description: this.plan.description,
          typeId: this.plan.typeId,
          userName: "",
          partnerName: "",
          planTypeName: "",
          locationTypeId: this.planIsInPlace == true ? 3 : 4,
          locationTypeName: "",

          createdBy: this.myId,
          updatedBy: 0,
          deletedBy: 0,
        };

        this.planService.add(plan).subscribe((res: any) => {
          this.informationService.add(res.message);

        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          window.location.reload();
        });
      }
    }

  }
  delete(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.planService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
    });
  }

  changeSaturdayPlan() {
    if (this.saturdayPlans == true) {
      this.saturdayPlans = false;
    }
    else {
      this.saturdayPlans = true;
    }
    this.includeWeekendPlans();
  }

  changeSundayPlan() {
    if (this.sundayPlans == true) {
      this.sundayPlans = false;
    }
    else {
      this.sundayPlans = true;
    }
    this.includeWeekendPlans();
  }

  changeTripType() {
    this.planIsOutOfTown = !this.planIsOutOfTown;
  }
  changeLocationType() {
    this.planIsInPlace = !this.planIsInPlace;

  }
  requiredPartnerSelection: boolean = false;
  changeType(type: number) {
    if (type == 1 || type == 2) {
      this.requiredPartnerSelection = false;
    }
    else {
      this.requiredPartnerSelection = true;
    }
  }

  tableByFiltersId() {
    if (this.userRole == "Admin") {

      this.planService.getList().subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
        }

        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }

        this.calendarOptions.events = filteredItems.map((event) => {
          if (event.partnerId != 0) {
            return {
              title: event.partnerName + " - " + event.userName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });

    }
    if (this.userRole == "Consultant") {
      this.planService.getList().subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
        }

        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }

        this.calendarOptions.events = filteredItems.map((event) => {
          if (event.partnerId != 0) {
            return {
              title: event.partnerName + " - " + event.userName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
    if (this.userRole == "ConsultantUser") {
      this.planService.getListByUserId(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
        }

        this.calendarOptions.events = filteredItems.map((event) => {
          if (event.partnerId != 0) {
            return {
              title: event.partnerName + " - " + event.userName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
  }

  toggleCheckboxAll(event) {
    this.checkAll = event.target.checked;
    if (this.checkAll) {
      this.calendarRef.map(res => {
        res.checked = true;
      });
    } else {
      this.calendarRef.map(res => {
        res.checked = false;
      });
    }
  }


  mappedUsers: User[] = [];
  public selectControlDepartment = new FormControl();

  getDepartmentList() {
    this.userService.getListForFilter().subscribe((res: any) => {
      this.users = res.data;
      this.mappedUsers = res.data.map((x: any) => {
        return { optionId: x.id, optionTitle: x.firstName };
      })
    });
  }
  changeSectorId() {
    this.plan.userId = this.selectControlDepartment.value;
  }
}
