<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">Evo</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center" style="margin-bottom: 6px;">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')" style="margin-top:6px;">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class="nav-item d-lg-block" style="list-style-type: none;">
      <a type="button" class="nav-link nav-link-style btn " style="margin-top:10px;" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>

    <li *ngIf="userRole=='Consultant' || userRole=='ConsultantUser' || userRole=='Admin'" class="nav-item d-lg-block"
      style="list-style-type: none;">
      <a [routerLink]="['/home/infoplan']" title="Takvim"><span [data-feather]="'calendar'"
          class="ml-1 ficon font-medium-5 feather"></span></a>

      <a [routerLink]="['/home/expenses']" title="Masraf"><span [data-feather]="'tabler-receipt'"
          class="ml-2 ficon font-medium-5 feather">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-receipt" width="25" height="25"
            viewBox="0 0 24 18" stroke-width="1.5" stroke="#00b341" fill="none" stroke-linecap="round"
            stroke-linejoin="round" style="margin-top:5px;">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />
          </svg>
        </span></a>
    </li>


  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span
          class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>

    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{userName}}</span><span class="user-status">
            <div *ngIf="userRole=='Consultant'">Danışman Yönetici</div>
            <div *ngIf="userRole=='ConsultantUser'">Danışman Kullanıcı</div>
            <div *ngIf="userRole=='Customer'">Müşteri Yönetici</div>
            <div *ngIf="userRole=='CustomerUser'">Müşteri Kullanıcı</div>
            <div *ngIf="userRole=='Admin'">Admin</div>
          </span>
        </div>
        <div class="d-flex">
          <div *ngIf="user.imageURL!=null" class="avatar" placement="top">
            <img src="../../../../assets/images/img/{{user.imageURL}}" class="circle" alt="{{getInitials(userName) }}"
              width="40" height="40" />
            <!-- margin 5 px verilebilir -->
          </div>
          <div *ngIf="user.imageURL==null" class="avatar ml-0 ng-star-inserted bg-light-success"
            style="width: 40px; height: 40px;" placement="top">
            <div class="avatar-content text-center" style="font-size: medium; margin: 5px 0px 0px 5px;">
              {{ getInitials(userName) }}
            </div>
          </div>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem routerLink="/home/myprofile"><span [data-feather]="'user'" [class]="'mr-50'"></span>
          Profilim</a>
        <a *ngIf="userRole=='Admin'" ngbDropdownItem routerLink="/home/settings"><span [data-feather]="'settings'" [class]="'mr-50'"></span>
          Sistem Ayarları</a>
        <a ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'mail'" [class]="'mr-50'"></span>
          Gelen Kutum</a>
        <button ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span>
          Çıkış</button>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>