import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoExpense'
})
export class EvoExpensePipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const description = p.description.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const payment =String(p.payment)?.includes(filterText);
      return (description +payment);
    });
  }
}
