import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoProject'
})
export class EvoProjectPipe implements PipeTransform {

  transform(value: any[], filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const projectTitle = p.title.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const projectDescription = p.description.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      return (projectTitle + projectDescription);
    });
  }
}
