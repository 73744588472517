import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Register } from '../models/register';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class EvoUserService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<User[]>{
    return this.httpClient.get<User[]>(this.baseApiUrl+'GetUserList');
  }
  getListForFilter():Observable<User[]>{
    return this.httpClient.get<User[]>(this.baseApiUrl+'GetUserListForFilter');
  }
  getInternalSourceUsers():Observable<User[]>{
    return this.httpClient.get<User[]>(this.baseApiUrl+'GetInternalSourceUsers');
  }
  get (id: number):Observable <User> {
    return this.httpClient.get<User>(this.baseApiUrl+'GetUser/'+id);
  }
  register(register:Register){
    return this.httpClient.post(this.baseApiUrl+'Register/',register);
  }
  add(user:User):Observable<User>{
    return this.httpClient.post<User>(this.baseApiUrl+'AddUser/',user);
  }
  update(user:User):Observable<User>{
    return this.httpClient.post<User>(this.baseApiUrl+"UpdateUser/",user);
  }
  updateType(user:User):Observable<User>{
    return this.httpClient.post<User>(this.baseApiUrl+"UpdateUserType/",user);
  }
  profileUpdate(user:User):Observable<User>{
    return this.httpClient.post<User>(this.baseApiUrl+"ProfileUpdate/",user);
  }
  profileImageUpdate(user:any):Observable<any>{
    return this.httpClient.post<any>(this.baseApiUrl+"ProfileImageUpdate/",user);
  }
  oldPassword(user:User){
    return this.httpClient.post(this.baseApiUrl+"OldPasswordCheck/",user);
  }
  passwordUpdate(user:User):Observable<User>{
    return this.httpClient.post<User>(this.baseApiUrl+"PasswordUpdate/",user);
  }
  delete(user:User):Observable<User>{
    return this.httpClient.post<User>(this.baseApiUrl+"DeleteUser/",user);
  }
  
}
