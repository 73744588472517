import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoContract'
})
export class EvoContractPipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const contractNumber = p.contractNumber.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const moduleName = p.moduleName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const userName = p.userName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const projectName = p.projectName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      return (contractNumber+userName+moduleName+projectName);
    });
  }

}
