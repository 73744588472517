import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { BillType } from 'app/evo/models/billtype';
import { PartnerUser } from 'app/evo/models/partneruser';
import { Department } from 'app/evo/models/department';
import { Priority } from 'app/evo/models/priority';
import { Project } from 'app/evo/models/project';
import { Ticket } from 'app/evo/models/ticket';
import { TicketType } from 'app/evo/models/tickettype';
import { TicketUser } from 'app/evo/models/ticketuser';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoTicketService } from 'app/evo/services/evo-ticket.service';
import { EvoTicketUserService } from 'app/evo/services/evo-ticketuser.service';
import { InformationService } from 'app/evo/services/information.service';
import * as snippet from '../layout/quill-editor.snippetcode';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { Partner } from 'app/evo/models/partner';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { Module } from 'app/evo/models/module';

@Component({
  selector: 'app-evo-ticket-add-form',
  templateUrl: './evo-ticket-add-form.component.html',
  styles: [
  ],
  providers: [EvoTicketService]
})
export class EvoTicketAddFormComponent implements OnInit {

  quillEditorContent = snippet.quillEditorContent;

  myId: number;
  role: string = "";
  domain: string = "";
  selectedProject: number = 0;
  buttonClicked: boolean = true;

  ticket: Ticket = new Ticket();
  ticketUser: TicketUser = new TicketUser();
  partner: Partner = new Partner();
  partnerUser: PartnerUser = new PartnerUser();
  department: Department = new Department();
  addedDepartment: Department = new Department();
  priority: Priority = new Priority();
  billType: BillType = new BillType();
  type: TicketType = new TicketType();
  project: Project = new Project();
  module: Module = new Module();

  billTypes: BillType[] = [
    { id: 4, name: "Faturalanmadı" },
    { id: 5, name: "Faturalandı" },
    { id: 6, name: "Feragat Edildi" }
  ];
  ticketTypes: TicketType[] = [
    { id: 1, name: "Destek Talebi" },
    { id: 2, name: "Müşteri Planı" },
    { id: 3, name: "Proje Planı" },
  ];
  priorities: Priority[] = [
    { id: 1, name: "Yüksek" },
    { id: 2, name: "Normal" },
    { id: 3, name: "Düşük" },
  ];
  departments: Department[];
  partners: Partner[] = [];
  projects: Project[] = [];
  modules: Module[] = [];

  constructor(
    private decodeService: DecodeService,
    private partnerUserService: EvoPartnerUserService,
    private ticketService: EvoTicketService,
    private ticketUserService: EvoTicketUserService,
    private departmentService: EvoDepartmentService,
    private partnerService: EvoPartnerService,
    private projectService: EvoProjectService,
    private moduleService: EvoModuleService,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.domain = window.location.hostname;
    this.myId = this.decodeService.getUserId();

    let partnerUserId = this.decodeService.getUserId();
    this.role = this.decodeService.getRole();
    if (this.role == 'Customer' || this.role == 'CustomerUser') {
      this.partnerUserService.getByUserId(partnerUserId).subscribe((res: any) => {
        this.projectService.getListofContinuingByPartnerId(res.data.partnerId).subscribe((res: any) => {
          this.projects = res.data;
        })
      });
    }
    if (this.role == "Admin" || this.role == "Consultant" || this.role == "ConsultantUser") {
      this.partnerService.getList().subscribe((res: any) => {
        this.partners = res.data;
      });
    }
    this.moduleService.getList().subscribe((res: any) => {
      this.modules = res.data;
    });
    this.listOfDepartments();
  }

  listOfDepartments() {
    this.departmentService.getList().subscribe((items: any) => {
      this.departments = items.data;
    });
  }
  isAddingTicket: boolean = false;

  add(subject: string, expectedTime: number, partnerTicketNo: string) {
    if (this.isAddingTicket) {
      return;
    }
    this.isAddingTicket = true;

    if (this.project.id == 0) {
      this.informationService.warning("Lütfen Proje Seçiniz");
      this.isAddingTicket = false;
    } else {
      this.ticket = {
        id: 0,
        trackingNumber: ("00000000"),
        subject: subject,
        projectId: this.project.id,
        projectName: "",
        priorityId: this.priority.id,
        statusId: 1,
        departmentId: this.department.id,
        typeId: this.type.id,
        typeName: "",
        billTypeId: 4,
        billTypeName: "",
        answerTypeId: 2,
        answerTypeName: "",
        expectedTime: expectedTime || 0,
        departmentName: "",
        partnerId: 0,
        partnerName: "",
        moduleId: this.module.id,
        moduleName: "",
        partnerTicketNo: partnerTicketNo,
        createdBy: this.myId,
        createdDate: new Date(),
        createdByUserName: "",

        updatedBy: 0,
        deletedBy: 0,
      };
      this.ticketService.add(this.ticket).subscribe((item: any) => {
        this.informationService.add(item.message);
        this.isAddingTicket = false;
        let ticketId = item.data.id;
        setTimeout(() => {
          this.router.navigate(["/home/details/" + ticketId]);
        }, 50);

      }, (err) => {
        this.errorService.errorHandler(err);
        this.isAddingTicket = false;
      });
    }
  }

  addDepartment(name: string) {
    this.addedDepartment = {
      id: 0,
      name: name,
    };
    this.departmentService.add(this.addedDepartment).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      this.listOfDepartments();
    });
  }

  changePartner() {
    this.project = new Project();
    this.project.id = 0;
    this.projectService.getListofContinuingByPartnerId(this.partner.id).subscribe((res: any) => {
      this.projects = res.data;
    });
  }
}
