<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-8 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Partner Oluştur</h4>

                        <div class="card-body">
                            <form class="form form-vertical" #UserForm="ngForm">
                                <div class="row">

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Partner Adını Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtFirstName" placeholder="Partner Adı" #txtFirstName="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtFirstName.invalid&& txtFirstName.touched">Partner Adı boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Partner Unvanını Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtTitle" placeholder="Partner Ünvanı" #txtTitle="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtTitle.invalid&& txtTitle.touched">Partner Unvanı boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Partner Mail Adresini Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtMailAddress" placeholder="Mail Adresi"
                                                #txtMailAddress="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtMailAddress.invalid&& txtMailAddress.touched">Mail Adresi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical"> Yazışma Adresini Giriniz</label>
                                            <textarea ngModel required id="name-vertical" style="height: 75px;"
                                                class="form-control" name="txtMainAddress" placeholder="Yazışma Adresi"
                                                #txtMainAddress="ngModel"></textarea>

                                            <small class="text-danger"
                                                *ngIf="txtMainAddress.invalid&& txtMainAddress.touched">Yazışma Adresi
                                                boş bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical"> Fatura Adresini Giriniz</label>
                                            <textarea ngModel required id="name-vertical" style="height: 75px;"
                                                id="name-vertical" class="form-control" name="txtInvoceAddress"
                                                placeholder="Fatura Adresi" #txtInvoceAddress="ngModel"></textarea>

                                            <small class="text-danger"
                                                *ngIf="txtInvoceAddress.invalid&& txtInvoceAddress.touched">Fatura
                                                Adresi boş bırakılamaz!</small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Partnerin Vergi Dairesi Bilgisini Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtTaxOffice" placeholder="Vergi Dairesi"
                                                #txtTaxOffice="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtTaxOffice.invalid&& txtTaxOffice.touched">Vergi Dairesi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Partnerin Vergi Numarası Bilgisini
                                                Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtTaxNumber" placeholder="Vergi Numarası"
                                                #txtTaxNumber="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtTaxNumber.invalid&& txtTaxNumber.touched">Vergi Numarası boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Müştesi Telefon Numarasını Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtPhoneNumber" placeholder="Telefon Numarası"
                                                #txtPhoneNumber="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtPhoneNumber.invalid&& txtPhoneNumber.touched">Mail Adresi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Açıklama Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtDescription" placeholder="Açıklama"
                                                #txtDescription="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtDescription.invalid&& txtDescription.touched">Açıklama boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="col-2">
                                        <div class="custom-control custom-control-success custom-switch">
                                            <input type="checkbox" checked (click)="changePartnerCustomer()"
                                                class="custom-control-input" id="customSwitchCustomer" />
                                            <label class="custom-control-label"
                                                for="customSwitchCustomer">Müşteri</label>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="custom-control custom-control-success custom-switch">
                                            <input type="checkbox" (click)="changePartnerSupplier()"
                                                class="custom-control-input" id="customSwitchSupplier" />
                                            <label class="custom-control-label"
                                                for="customSwitchSupplier">Tedarikçi</label>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="isSupplier==true else emptyAgreementRateTemplate">
                                        <div class="form-group">
                                            <label for="name-vertical">Partner ile Yapılan Anlaşma Oranı Bilgisini
                                                Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtAgreementRate" placeholder="Anlaşma Oranı"
                                                #txtAgreementRate="ngModel" [(ngModel)]="agreementRate" />

                                            <small class="text-danger"
                                                *ngIf="txtAgreementRate.invalid&& txtAgreementRate.touched">Anlaşma
                                                Oranı boş bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <ng-template #emptyAgreementRateTemplate>

                                    </ng-template>

                                    <div *ngIf="UserForm.valid &&(isSupplier||isCustomer) else disabledButtonTemplate"
                                        class="col-12">
                                        <button type="submit" rippleEffect class="btn btn-success mr-1"
                                            (click)="add(txtFirstName.value,txtTitle.value,txtPhoneNumber.value,txtMailAddress.value, txtMainAddress.value,txtInvoceAddress.value,txtTaxOffice.value,txtTaxNumber.value,txtDescription.value)">Kaydet</button>
                                    </div>
                                    <ng-template #disabledButtonTemplate>
                                        <div class="col-12">
                                            <button type="submit" disabled rippleEffect
                                                class="btn btn-success mr-1">Kaydet</button>
                                        </div>
                                    </ng-template>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                </div>

            </div>
        </section>
    </div>
</div>