import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoUserOperationClaim'
})
export class EvoUserOperationClaimPipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const userName = p.userName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const operationClaimTitle = p.operationClaimTitle.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      return (userName+operationClaimTitle);
    });
  }

}
