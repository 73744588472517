import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoPartnerUser'
})
export class EvoPartnerUserPipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const userName = p.userName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const partnerName = p.partnerName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      return (userName+partnerName);
    });
  }

}
