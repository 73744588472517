import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  // {
  //   id: 'dashboard',
  //   title: 'Dashboard',
  //   type: 'collapsible',
  //   // role: ['Admin'], //? To hide collapsible based on user role
  //   icon: 'home',
  //   badge: {
  //     title: '2',
  //     translate: 'MENU.DASHBOARD.BADGE',
  //     classes: 'badge-light-warning badge-pill'
  //   },
  //   children: [
  //     {
  //       id: 'analytics',
  //       title: 'Analytics',
  //       translate: 'MENU.DASHBOARD.ANALYTICS',
  //       type: 'item',
  //      // role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
  //       icon: 'circle',
  //       url: 'dashboard/analytics'
  //     },
  //     {
  //       // If role is not assigned will be display to all
  //       id: 'ecommerce',
  //       title: 'eCommerce',
  //       translate: 'MENU.DASHBOARD.ECOMMERCE',
  //       type: 'item',
  //       icon: 'circle',
  //       url: 'dashboard/ecommerce'
  //     }
  //   ]
  // },
  {
    id: 'departmentList',
    title: 'Bölümler',
    type: 'item',
    classes: "pull-up",
    icon: 'archive',
    url: '/home/departments'
  },
  {
    id: 'projectList',
    title: 'Projeler',
    type: 'item',
    classes: "pull-up",
    icon: 'database',
    url: '/home/projects'
  },

  {
    id: 'ticket',
    type: 'section',
    title: '',
    classes:"mt-0 mb-5",

    icon: 'package',
    children: [
      {
        id: 'tickets',
        title: 'Talepler',
        type: 'collapsible',
        classes:"mt-0 mb-2",
        icon: 'server',
        children: [
          {
            id: 'ticketlist',
            title: 'Bütün Talepler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'myticketlist',
            title: 'Taleplerim',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
       
        ]
      }]
  },  
  // {
  //   id: 'ticketlist',
  //   title: 'Bütün Talepler',
  //   type: 'item',
  //   classes: "pull-up",
  //   icon: 'server',
  //   url: '/home/tickets'
  // },
  // {
  //   id: 'myticketlist',
  //   title: 'Taleplerim',
  //   type: 'item',
  //   classes: "pull-up",
  //   icon: 'eye',
  //   url: '/home/tickets'
  // },
  {
    id: 'ticketactivitylist',
    title: 'Aktiviteler',
    type: 'item',
    classes: "pull-up",
    icon: 'clock',
    url: '/home/ticketactivities'
  },
  {
    id: 'userlist',
    title: 'Danışmanlar',
    type: 'item',
    classes: "pull-up",
    icon: 'users',
    url: '/home/users'
  },
  {
    id: 'partneruserlist',
    title: 'Kullanıcılarım',
    type: 'item',
    classes: "pull-up",
    icon: 'users',
    url: '/home/partnerusers/'
  },
  {
    id: 'partnerlist',
    title: 'Cariler',
    type: 'item',
    classes: "pull-up",
    icon: 'briefcase',
    url: '/home/partners'
  },
  {
    id: 'planlist',
    title: 'Planlar',
    type: 'item',
    classes: "pull-up",
    icon: 'calendar',
    url: '/home/plans'
  },
  {
    id: 'modulelist',
    title: 'Moduller',
    type: 'item',
    classes: "pull-up",
    icon: 'package',
    url: '/home/modules'
  },
  {
    id: 'contractlist',
    title: 'Sözleşmeler',
    type: 'item',
    classes: "pull-up",
    icon: 'file-text',
    url: '/home/contracts'
  },
  {
    id: 'operationclaimlist',
    title: 'Yetkiler',
    type: 'item',
    classes: "pull-up",
    icon: 'key',
    url: '/home/operationclaims'
  },
  {
    id: 'expenelist',
    title: 'Masraflar',
    type: 'item',
    classes: "pull-up",
    icon: 'credit-card',
    url: '/home/expenses'
  },
  {
    id: 'goalList',
    title: 'Hedef Kartları',
    type: 'item',
    classes: "pull-up",
    icon: 'compass',
    url: '/home/goals'
  },
  {
    id: 'competenceList',
    title: 'Yetkinlikler',
    type: 'item',
    classes: "pull-up",
    icon: 'bar-chart-2',
    url: '/home/competences'
  },
  {
    id: 'vacationlist',
    title: 'Tatiller',
    type: 'item',
    classes: "pull-up",
    icon: 'calendar',
    url: '/home/vacations'
  },

  {
    id: 'reports',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'reportlist',
        title: 'Raporlar',
        type: 'collapsible',
        // icon: 'shopping-cart',
        icon: 'tabler-align-left',
        children: [
          {
            id: 'ticketsstatuswihnew',
            title: 'Atanmamış Talepler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithpartner',
            title: 'Müşteriden Cevap Beklenen',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithconsultant',
            title: 'Danışmandan Cevap Beklenen',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithcanselled',
            title: 'İptal Edilmiş',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'effortslist',
            title: 'Efor Onayı Bekleyen',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'transactionreportlist',
            title: 'İşlem Raporları',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/transactions'
          },
        ]
      }]
  }
]
