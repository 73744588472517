import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { User } from 'app/evo/models/user';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';
import { ErrorService } from 'app/evo/services/error.service';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-evo-profile-settings',
  templateUrl: './evo-profile-settings.component.html',
  styles: [
  ]
})
export class EvoProfileSettingsComponent implements OnInit {

  public passwordTextTypeOld = false;
  public passwordTextTypeNew = false;
  public passwordTextTypeRetype = false;

  user: User = new User();
  id:number;
  documents: any[]=[];

  constructor(
    private decodeService:DecodeService,
    private router: Router,
    private userService:EvoUserService,
    private informationService:InformationService,
    private errorService:ErrorService
    
    ) {}

  ngOnInit() {
    this.id=this.decodeService.getUserId();
    this.userService.get(this.id).subscribe((res:any)=>{
      this.user=res.data;
    });
  }

  togglePasswordTextTypeOld() {
    this.passwordTextTypeOld = !this.passwordTextTypeOld;
  }

  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }

  togglePasswordTextTypeRetype() {
    this.passwordTextTypeRetype = !this.passwordTextTypeRetype;
  }



  updateMyProfile() {
    this.user.password="",
    this.user.updatedBy=this.decodeService.getUserId();
    this.user.deletedBy=0;
    this.userService.profileUpdate(this.user).subscribe((res:any)=>{
      this.informationService.update(res.message);
      this.ngOnInit();
    },(err:any)=>{
      this.errorService.errorHandler(err);
    });
  }

  uploadImage(){
    let formData=new FormData();
    formData.append("id",this.user.id.toString());
    for (let i = 0; i < this.documents.length; i++) {
      formData.append("document", this.documents[i]);
    }
    this.user.updatedBy=this.decodeService.getUserId();
    this.user.deletedBy=0;
    this.userService.profileImageUpdate(formData).subscribe((res:any)=>{
      this.informationService.update(res.message);
      window.location.reload();
    },(err:any)=>{
      this.errorService.errorHandler(550);
    });
  }

  passwordCheck(oldPassword:string,password:string){
    this.user.password=oldPassword;
    this.user.deletedBy=0;
    this.userService.oldPassword(this.user).subscribe((res:any)=>{
      this.passwordUpdate(password);
    },(err)=>{
      this.errorService.errorHandler(err);
    })
    // this.userService.passwordUpdate(this.user).subscribe((res:any)=>{
    //   this.informationService.update(res.message);
    //   this.ngOnInit();
    // },(err:any)=>{
    //   this.errorService.errorHandler(err);
    // });
  }

  passwordUpdate(password:string){
    this.user.password=password;
    this.userService.passwordUpdate(this.user).subscribe((res:any)=>{
      this.informationService.update("Şifre Değiştirildi Yeniden Giriş Yapmalısınız!!!");
    },(err:any)=>{
      this.errorService.errorHandler(err);
    });
    setTimeout(() => {
      localStorage.clear();
      this.router.navigate(['pages/authentication/login-v2']);
    }, 500);
  }

  getDocument(event: any) {
    this.documents = event.target.files;
  }
}
