import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoTicketActivity'
})
export class EvoTicketActivityPipe implements PipeTransform {

  transform(value: any[], filterText: string): any {
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p => {
      const userName = p.userName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const description= p.description?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const time =  String(p.time)?.includes(filterText);
      const moduleName=p.moduleName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const ticketSubject=p.ticketSubject?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const trackingNumber = String(p.trackingNumber)?.includes(filterText);

      return (userName ||trackingNumber|| description ||time||moduleName || ticketSubject);
    });
  }
}
