import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Contract } from 'app/evo/models/contract';
import { ContractDetail } from 'app/evo/models/contractdetail';
import { Partner } from 'app/evo/models/partner';
import { Module } from 'app/evo/models/module';
import { Project } from 'app/evo/models/project';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoContractDetailService } from 'app/evo/services/evo-contract-detail.service';
import { EvoContractService } from 'app/evo/services/evo-contract.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';

@Component({
  selector: 'app-evo-contract-update-form',
  templateUrl: './evo-contract-update-form.component.html',
  styles: [
  ]
})
export class EvoContractUpdateFormComponent implements OnInit {

  constructor(
    private contractService: EvoContractService,
    private contractDetailService: EvoContractDetailService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private projectService: EvoProjectService,
    private moduleServive: EvoModuleService,
    private transactionService: EvoTransactionService,
    private route: ActivatedRoute,
    private router: Router,
    private decodeService: DecodeService,
    private informationService: InformationService,
    private errorService: ErrorService,
  ) { }
  public basicSelectedOption = 10;

  id: number;
  myId: number;
  flag: number = 0;
  deletedContractDetailId: number;

  partners: Partner[] = [];
  projects: Project[] = [];
  users: User[] = [];
  modules: Module[] = [];
  contractDetails: ContractDetail[] = [];

  user: User = new User();
  project: Project = new Project();
  module: Module = new Module();
  partner: Partner = new Partner();
  contract: Contract = new Contract();
  contractDetail: ContractDetail = new ContractDetail();

  ngOnInit(): void {
    this.myId = this.decodeService.getUserId();
    let item = this.decodeService.getRole();
    this.id = this.route.snapshot.params['Id'];
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(this.id, 4, 5, this.myId).subscribe((res: any) => {
      });
    }
    this.id = this.route.snapshot.params['Id'];
    this.contractService.get(this.id).subscribe((res: any) => {
      this.contract = res.data;
      this.contractDetailService.getList(this.id).subscribe((res: any) => {
        this.contractDetails = res.data;
      });
      this.projectService.get(this.contract.projectId).subscribe((res: any) => {
        this.project = res.data;
        this.partnerService.get(this.project.partnerId).subscribe((res: any) => {
          this.partner = res.data;
          this.projectService.getListByPartnerId(this.partner.id).subscribe((res: any) => {
            this.projects = res.data;
          });
        })
      });
    });
    this.moduleServive.getList().subscribe((res: any) => {
      this.modules = res.data;
    });
    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data;
    });
  }

  changePartner() {
    this.project.id=0;
    this.projectService.getListByPartnerId(this.partner.id).subscribe((res: any) => {
      this.projects = res.data;
    });
  }

  getContractDetail(id: number) {
    this.contractDetailService.get(id).subscribe((res: any) => {
      this.contractDetail = res.data;
      console.log(this.contractDetail);
    });
  }

  update() {

    if (this.project.id==0){
      this.informationService.warning("Proje seçiniz");
      return;
    }
    if (this.contract.startDate > this.contract.endDate) {

      let temp;
      temp = this.contract.startDate;
      this.contract.startDate = this.contract.endDate;
      this.contract.endDate = temp;
    }

    this.contract.projectId = this.project.id;
    this.contract.updatedBy = this.myId;
    this.contract.deletedBy = 0;
    this.contractService.update(this.contract).subscribe((res: any) => {
      this.informationService.update(res.message);
    }, (err: any) => {
      this.errorService.errorHandler(err);
    }, () => {
      this.ngOnInit();
    });
  }


  addContractDetail(price: string) {
    this.flag = 0;
    let moduleName = "";

    if (this.user.id == null) {
      for (let i = 0; i < this.contractDetails.length; i++) {
        if (this.contractDetails[i].moduleId == this.module.id) {
          this.flag = 2;
          break;
        }
      }
    }

    for (let i = 0; i < this.contractDetails.length; i++) {

      if (this.contractDetails[i].moduleId == this.module.id) {

        if (this.contractDetails[i].userId == this.user.id) {
          this.flag = 1;
          break;
        }
      }
    }

    if (this.user.id != null && this.module.id != null && this.flag != 1) {

      for (let i = 0; i < this.contractDetails.length; i++) {

        if (this.contractDetails[i].moduleId == this.module.id) {

          if (this.contractDetails[i].userId == 0) {
            this.flag = 3;
            moduleName = this.contractDetails[i].moduleName;
            break;
          }
        }
      }
    }

    if (this.flag == 0) {
      this.contractDetail = {
        id: 0,
        contractId: this.contract.id,
        moduleId: this.module.id,
        userId: this.user.id,
        price: parseFloat(price),
        userName: "",
        moduleName: "",
        createdBy: this.myId,
        updatedBy: 0,
        deletedBy: 0,
      }
      if (this.user.id == undefined) {
        this.contractDetail.userId = 0;
      }
      this.contractDetailService.add(this.contractDetail).subscribe((res: any) => {
        this.informationService.add(res.message);
      }, (err: any) => {
        this.errorService.errorHandler(err);
      }, () => {
        window.location.reload();
      });
    }
    else if (this.flag == 1) {
      this.informationService.warning("Bu kullanıcıya ait modül zaten eklenmiş");
    }
    else if (this.flag == 2) {
      this.informationService.warning("Bu modül zaten eklenmiş");
    }
    else if (this.flag == 3) {
      this.contractDetail = {
        id: 0,
        contractId: this.contract.id,
        moduleId: this.module.id,
        userId: this.user.id,
        price: parseFloat(price),
        userName: "",
        moduleName: "",

        createdBy: this.myId,
        updatedBy: 0,
        deletedBy: 0,
      }
      if (this.user.id == undefined) {
        this.contractDetail.userId = 0;
      }
      this.contractDetailService.add(this.contractDetail).subscribe((res: any) => {
        this.informationService.delete("Uyarı! " + moduleName + " modüle ait genel kapsamlı bir anlaşma da bulunmaktadır.");
      }, (err: any) => {
        this.errorService.errorHandler(err);
      }, () => {
        this.ngOnInit();
      });
    }
  }

  updateContractDetail() {
    this.flag = 0;
    let moduleName = "";

    if (this.contractDetail.userId == null) {
      for (let i = 0; i < this.contractDetails.length; i++) {
        if (this.contractDetails[i].moduleId == this.module.id) {
          this.flag = 2;
          break;
        }
      }
    }

    for (let i = 0; i < this.contractDetails.length; i++) {

      if (this.contractDetails[i].moduleId == this.contractDetail.moduleId) {

        if (this.contractDetails[i].userId == this.contractDetail.userId) {
          this.flag = 1;
          break;
        }
      }
    }

    if (this.contractDetail.userId != null && this.contractDetail.moduleId != null && this.flag != 1) {

      for (let i = 0; i < this.contractDetails.length; i++) {

        if (this.contractDetails[i].moduleId == this.contractDetail.moduleId) {

          if (this.contractDetails[i].userId == 0) {
            this.flag = 3;
            moduleName = this.contractDetails[i].moduleName;
            break;
          }
        }
      }
    }

    if (this.flag == 0) {
      this.contractDetail.updatedBy = this.myId;
      this.contractDetail.deletedBy = 0;
      this.contractDetailService.update(this.contractDetail).subscribe((res: any) => {
        this.informationService.update(res.message);
      }, (err: any) => {
        this.errorService.errorHandler(err);
      }, () => {
        window.location.reload();
      });
    }
    else if (this.flag == 1) {
      this.informationService.warning("Bu kullanıcıya ait modül zaten eklenmiş");
    }
    else if (this.flag == 2) {
      this.informationService.warning("Bu modül zaten eklenmiş");
    }
    else if (this.flag == 3) {
      this.contractDetail.updatedBy = this.myId;
      this.contractDetail.deletedBy = 0;
      this.contractDetailService.update(this.contractDetail).subscribe((res: any) => {
        this.informationService.delete("Uyarı! " + moduleName + " modüle ait genel kapsamlı bir anlaşma da bulunmaktadır.");
      }, (err: any) => {
        this.errorService.errorHandler(err);
      }, () => {
        this.ngOnInit();
      });
    }
  }

  deleteContractDetail(id: number) {
    this.contractDetailService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;
      this.contractDetailService.delete(item.data).subscribe((res: any) => {
        this.informationService.delete(res.message);
      }, (err: any) => {
        this.errorService.errorHandler(err);
      }, () => {
        window.location.reload();
      });
    });
  }
}
